<!--
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-26 23:18:49
 * @LastEditors: v_binqin
 * @LastEditTime: 2021-06-29 20:31:12
-->
<!--  -->
<template>
  <div class="police-list">
    <van-list
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index">
        <div class="list-data">
          <span class="time"> {{ item.createdAt | formatDate }} </span>
          <div>
            <span> {{ item.deviceName }}发生燃气报警 </span>
            <van-button
              v-if="item.disposed"
              round
              plain
              hairline
              type="info"
              size="mini"
            >
              已解除
            </van-button>
            <van-button
              v-else
              hairline
              type="info"
              size="small"
              @click="reset(item)"
            >
              解 除
            </van-button>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      page: 1,
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.http
        .get('/gas/exception/list', {
          params: {
            companyId: localStorage.getItem('companyId'),
            page: this.page,
            size: 10,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.loading = false
            this.list = this.list.concat(data.data.list)
            if (this.page == data.data.lastPage || !data.data.hasNextPage) {
              this.finished = true
            }
            this.page++
          } else {
            this.loading = false
            this.error = true
          }
        }).catch(err=>{
          this.loading = false
          this.error = true
        })
    },
    reset(item) {
      this.http
        .get('/gas/reset', {
          params: {
            deviceId: item.deviceNo,
          },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.page = 1
            this.isOne = true
            this.finished = false
            this.list = []
            this.$toast.success('报警解除成功')
            // this.timer = setTimeout(() => {
            //     //设置延迟执行
            //     this.onLoad();
            //   }, 2000);
          }
        })
    },
  },
}
</script>
<style lang='less' scoped>
.list-data {
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>